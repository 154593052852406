<template>
  <base-detail @closePage="closePage">
    <!-- 销售基本信息 -->
    <detail-list :formData="tableRow" :config="config"> </detail-list>
    <!-- 设备列表(包含备件和故障)-->
    <h1 style="margin-top: 10px; position: relative">
      {{ $t('saleManage.equipment') }}
      <el-button
        size="mini"
        style="position: absolute; right: 0; top: -5px"
        @click="multipleExport"
      >
        <i class="iconfont el-icon-download" />
        {{ $t('functionEnum.CUSTOM_EQUIPMENT_MANAGE_EXPORT') }}
      </el-button>
    </h1>
    <table-list
      class="saleExportList"
      style="margin-top: 10px"
      :isDefine="true"
      :defaultContent="defaultContent"
      :columns="columns"
      :multiple="true"
      :treeProps="{ children: 'children' }"
      :highlightCurrentRow="false"
      v-model="subTableRows"
    >
      <!-- 类型 -->
      <template v-slot:level="{ row }">
        {{ levelNames[row.level] }}
      </template>
      <!-- 所属分组 -->
      <template v-slot:equipmentGroup="{ row }">
        {{ row.equipmentGroup.name }}
      </template>
      <!-- 导出 -->
      <template v-slot:option="{ row }">
        <el-button type="text" @click="signleExport(row)">
          {{ $t('base.export') }}
        </el-button>
      </template>
    </table-list>
  </base-detail>
</template>

<script>
import { importFile } from '@/utils/ajax'
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      // 设备信息
      config: [
        // lang: 所属分组
        {
          label: this.$t('equipmentManage.equipmentGroup'),
          prop: 'equipmentGroup',
          span: 12,
          callback(row) {
            return row.equipmentGroup && row.equipmentGroup.name
          },
        },
        // lang: 所属部门
        {
          label: this.$t('equipmentManage.equipmentUserAccountGroup'),
          prop: 'userAccountGroup',
          span: 12,
          callback(row) {
            return row.userAccountGroup && row.userAccountGroup.name
          },
        },
        // lang: 设备名称
        {
          label: this.$t('equipmentManage.equipmentName'),
          prop: 'name',
          span: 12,
        },
        // lang: 创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 12,
          callback: (row) => {
            return this.$util.format(row.createTime, 'yyyy-MM-dd')
          },
        },
      ],
      // 设备信息
      levelNames: {
        ONE: this.$t('equipmentManage.equipmentInfo'),
        TWO: this.$t('equipmentManage.partInfo'),
        THREE: this.$t('equipmentManage.faultInfo'),
      },
      columns: [
        // lang:类型
        {
          label: this.$t('equipmentManage.type'),
          prop: 'level',
          width: '140px',
        },
        // lang:所属分组
        {
          label: this.$t('equipmentManage.equipmentGroup'),
          prop: 'equipmentGroup',
        },
        // lang:所属部门
        {
          label: this.$t('equipmentManage.equipmentUserAccountGroup'),
          prop: 'userAccountGroup',
          callback(row) {
            return row.userAccountGroup && row.userAccountGroup.name
          },
        },
        // lang:名称
        {
          label: this.$t('equipmentManage.name'),
          prop: 'name',
        },
        // lang:编号
        {
          label: this.$t('equipmentManage.number'),
          prop: 'number',
        },
        // lang:型号
        {
          label: this.$t('equipmentManage.model'),
          prop: 'model',
        },
        // lang:规格
        {
          label: this.$t('equipmentManage.specifications'),
          prop: 'specifications',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
        },
      ],
      subTableRows: [],
      defaultContent: [],
    }
  },
  mounted() {
    this.defaultContent = [this.$util.copyData(this.tableRow)]
  },
  methods: {
    // 关闭页面按钮点击回调
    closePage() {
      this.$emit('closePage')
    },
    // 多个导出
    multipleExport() {
      if (!this.subTableRows.length) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      const ids = this.subTableRows.map((item) => item.id)
      this.exportMethod(ids.join(','))
    },
    // 单个导出
    signleExport(row) {
      this.exportMethod(row.id)
    },
    // 导出二维码
    exportMethod(ids) {
      importFile({
        baseUrl: this.$page.getBaseUrl(),
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.customEquipmentExportEquipmentCode,
        // lang:设备二维码
        name: this.$t('equipmentManage.code'),
        type: '.docx',
        data: {
          qrcodeSize: 170,
          logoSize: 10,
          fontWidth: 12,
          clo: 3,
          pageCount: 3,
          equipmentDetailId: ids,
        },
      })
    },
  },
}
</script>